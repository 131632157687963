export const Partners = (props) => {
  return (
    // const baseUrl = "https://aif-festival.herokuapp.com"
    <div id="partners" className="text-center" style={{ marginTop: "15px" }}>
      <div className="container">
        <div className="section-title">
          <h2>Support Us</h2>
          <p>
            You can support the festival in any way deem fit. Your support would be greatly appreciated and well documentation on how your supports are utilized and reports would be available on demand.
            <b>
              {" "}
              Please get in touch with us via <span style={{ color: "red" }}>admin@aihrff.hrjnet.org to support</span>{" "}
            </b>
          </p>
          <p>
            Thank you for your support for the Africa International Human Rights Film Festival project. We sincerely appreciate it. <br />
            Kindly make your donations to support the festival into our corporate account.
            <br />
            <ul>
              <b style={{ color: "red" }}>
                <li>Account Name: Human Rights Journalists Net</li>
                <li>Account Number: 0745591299 </li>
                <li>Bank: GTBANK</li>
              </b>
            </ul>
            <span>
              We look forward to more partnership with you in advancing human rights in Nigeria. <br />
              Kind regards, Kehinde Adegboyega Executive Director, Human Rights Journalists Network Nigeria
            </span>
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon} style={{ color: "blue", fontSize: "40px" }}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Partners;
