// import React, { useEffect, useState } from "react"
// import axios from "axios"

// const Team = () => {
//   const baseUrl = "https://aif-festival.herokuapp.com"
//   const [teams, setTeam] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [header, setHeader] = useState({})

//   const fetchHeader = async () => {
//     let query = `query{
//       hero{
//         data{
//           id
//           attributes{
//             Meet_the_team
//             Meet_the_tea_description
//           }
//         }
//       }
//     }`;

//     let response = await axios.post(`${baseUrl}/graphql`, { query: query })
//     if (response && response !== undefined && response !== null && response.error == null) {
//       setHeader(response.data.data.hero);
//       // console.log(response.data.data.hero.data.attributes.Meet_the_team);
//     }
//   }

//   const fetchTeam = async () => {
//     let query = `query{
//       teams{
//         data{
//           id
//           attributes{
//             name
//             designation
//             image{
//               data{
//                 attributes{
//                   url
//                 }
//               }
//             }
//             createdAt
//           }
//         }
//       }
//     }`;

//     let response = await axios.post(`${baseUrl}/graphql`, { query: query })
//     if (response && response !== undefined && response !== null && response.error == null) {
//       setTeam(response.data.data.teams);
//       // console.log(response.data.data.teams);
//       // console.log(response.data.data.teams.data[3].attributes.designation);
//       // console.log(response.data)
//       setLoading(false)
//     }
//   }

//   useEffect(() => {
//     fetchTeam()
//     fetchHeader()
//   }, [])


//   return (
//     <div id='team' className='text-center'>
//       <div className='container'>
//         <div className='col-md-8 col-md-offset-2 section-title'>
//           <h2>{header.data?.attributes?.Meet_the_team ? header.data?.attributes?.Meet_the_team : 'Loading'}</h2>
//           <p>
//             {header.data?.attributes?.Meet_the_tea_description ? header.data?.attributes?.Meet_the_tea_description : 'Loading'}
//           </p>
//         </div>

//         <div id='row'>
//           {teams?.data?.map((d, i) => (
//             <div key={i} className='col-md-3 col-sm-6 team'>
//               <div className='thumbnail'>
//                 <img
//                   src={d?.attributes?.image?.data?.attributes?.url}
//                   className="team-img"
//                   alt="" />{" "}
//                 <div className='caption'>
//                   <h4>{d?.attributes?.name}</h4>
//                   <p>{d?.attributes?.designation}</p>
//                 </div>
//               </div>
//             </div>
//           ))
//           }
//         </div>
//       </div>
//     </div>
//   )
// }
import Carousel from "react-multi-carousel";

export const Team = (props) => {

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };


  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>Meet our dedicated team behind everything</p>
        </div>
          <div id="rows">
        {/* <Carousel swipeable={true} draggable={true} showDots={true} responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={1000} removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}> */}
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                    <div className="thumbnail ">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" style={{ "border-radius": "100%" }} />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
        {/* </Carousel> */}
          </div>
      </div>
    </div>
  );
}


export default Team